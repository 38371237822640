// TravelPartnerPage.js
import React from "react";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaGlobe } from 'react-icons/fa';
import "./TravelPartnerPage.css";

const TravelPartnerPage = () => {
  return (
    <div className="travel-partner-container">
      <h1 className="travel-partner-title">Our Travel Partner</h1>
      <div className="travel-partner-card">
        <h2>Lhamu Tours and Travels</h2>
        <div className="travel-partner-info">
          <p><span className="icon-wrapper"><FaMapMarkerAlt className="icon" /></span> Gangtok, Sikkim</p>
          <p><span className="icon-wrapper"><FaGlobe className="icon" /></span> <a href="http://www.lhamutours.com" target="_blank" rel="noopener noreferrer">www.lhamutours.com</a></p>
          <p><span className="icon-wrapper"><FaEnvelope className="icon" /></span> <a href="mailto:lhamutours@gmail.com">lhamutours@gmail.com</a></p>
          <p><span className="icon-wrapper"><FaPhoneAlt className="icon" /></span> <a href="tel:+917407470477">7407470477</a>/<a href="tel:+918348770449">8348770449</a></p>
        </div>
      </div>
    </div>
  );
};

export default TravelPartnerPage;
