import React, { useState } from 'react';
import './Venue.css'; // Import your CSS file for styling
import hotel1 from '../assets/images/hotel1.jpg';
import hotel2 from '../assets/images/hotel2.jpg';
import hotel3 from '../assets/images/hotel3.jpg';
import hotel4 from '../assets/images/hotel4.jpg';
import hotel5 from '../assets/images/hotel5.jpg';
import hotel6 from '../assets/images/hotel6.jpg';
import { FaPhone, FaEnvelope } from 'react-icons/fa'; // Import icons for phone and email

const Venue = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="venue-container">
      <h1>Conference Venue</h1>
      <div className="venue-details">
        <div className="venue-info">
          <p>
            Lemon Tree Hotel, Middle Sichey, Near District Court, Gangtok, Sikkim 737101.
          </p>
          <p>
            <FaPhone /> +91 3592 284020 / +91 7477783223
          </p>
          <p>
            <FaEnvelope />{' '}
            <a href="mailto:hi.gt@lemontreehotels.com">hi.gt@lemontreehotels.com</a>
          </p>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14175.868149156448!2d88.608043!3d27.345499!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e6a530e46f31e7%3A0x8bba67d12f8ef5b9!2sLemon%20Tree%20Hotel%2C%20Gangtok!5e0!3m2!1sen!2sin!4v1726828925391!5m2!1sen!2sin"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>

        <div className="venue-images">
          <h2>Venue Images</h2>
          <div className="images-grid">
            <img
              src={hotel1}
              alt="Lemon Tree Hotel 1"
              onClick={() => openImageModal(hotel1)}
            />
            <img
              src={hotel2}
              alt="Lemon Tree Hotel 2"
              onClick={() => openImageModal(hotel2)}
            />
            <img
              src={hotel3}
              alt="Lemon Tree Hotel 3"
              onClick={() => openImageModal(hotel3)}
            />
            <img
              src={hotel4}
              alt="Lemon Tree Hotel 4"
              onClick={() => openImageModal(hotel4)}
            />
            <img
              src={hotel5}
              alt="Lemon Tree Hotel 4"
              onClick={() => openImageModal(hotel5)}
            />
            <img
              src={hotel6}
              alt="Lemon Tree Hotel 4"
              onClick={() => openImageModal(hotel6)}
            />
          </div>
        </div>
      </div>

      {selectedImage && (
        <div className="modal" onClick={closeImageModal}>
          <span className="close">&times;</span>
          <img className="modal-content" src={selectedImage} alt="Enlarged Venue" />
        </div>
      )}
      
    </div>

  );
};

export default Venue;
