import React, { useState } from 'react';
import axios from 'axios'; // Import axios for HTTP requests
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './AbstractSubmission.css';
import { DNA } from 'react-loader-spinner';

const AbstractSubmission = () => {
  const [submissionType, setSubmissionType] = useState('');
  const [conferenceId, setConferenceId] = useState('');
  const [file, setFile] = useState(null); // Manage file state
  const history = useNavigate(); // Initialize useNavigate hook
  const [loading, setLoading] = useState(false); // Define loading state

  const handleSubmissionTypeChange = (event) => {
    setSubmissionType(event.target.value);
  };

  const handleConferenceIdChange = (event) => {
    setConferenceId(event.target.value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Set the selected file
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append('conferenceId', conferenceId);
    formDataToSend.append('submissionType', submissionType);
    formDataToSend.append('file', file);

    try {
      const response = await axios.post('https://www.neoscon2025.in/abstract-submit', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        setLoading(false); // Hide loader
        history('/abstract-submission-success'); // Use history to redirect
      }
    } catch (error) {
      setLoading(false); // Hide loader
      console.error('Error:', error);
      alert(error.response.data.message); // Display the error message from Flask
    }
  };

  return (
    <div className="abstract-submission-container">
      <h1 className="abstract-title">Abstract Submission</h1>
      <p className="submission-note">Abstract Submission has been closed.</p>
      
      {loading && (
        <div className="loader-container">
          <DNA
            visible={true}
            height="100"
            width="100"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
            colors={['#0d243d', '#00afef']}
          />
        </div>
      )}
      
      {!loading && (
      <form className="abstract-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="conferenceId">Neoscon 2025 Conference ID</label>
          <input disabled
            type="text"
            id="conferenceId"
            value={conferenceId}
            onChange={handleConferenceIdChange}
            placeholder="Enter Neoscon 2025 Conference ID"
          />
        </div>
        {/* Add Guidelines for Abstract Submission */}
      <div className="guidelines-container">
        <h2>Guidelines for Abstract Submission for NEOSCON 2025</h2>
        <ol className="guidelines-list">
          <li>It should be structured with the following headings: Introduction, Materials and Methods, Results, Discussion & Conclusion in continuous fashion (No paragraphs).</li>
          <li>It should be written within 300 words in Times New Roman, 12 pt. font with double spacing for editing. Use “Sentence case,” not UPPER or lower case.</li>
          <li>No graphics or bibliography should be included in the abstract.</li>
          <li>The author must be a member of NEROSA and a registered delegate. PG students should submit a letter from HOD.</li>
          <li>Last date of submission: 31st December, 2024. Please email your abstract on or before this date to: <a href="mailto:neoscon2025@gmail.com">neoscon2025@gmail.com</a>. No abstract will be received after the deadline.</li>
          <li>Please specify the category in which you prefer to present: Free paper (Podium)/Poster Competition/Smt Maikan Borthakur Memorial Basic Science Competition/Dr DK Taneja NEROSA PG Gold Medal Competition.</li>
          <li>Abstracts of the competition category must be accompanied by FULL TEXT as per IJO (Indian Journal of Orthopaedics) manuscript submission guidelines. Published papers will not be accepted for this category.</li>
          <li>HODs of Medical Colleges with PG courses are requested to send TWO papers from each college for the NEROSA PG Gold Medal Competition. Basic Science and Poster Competition papers are open to all registered delegates with NEROSA membership.</li>
          <li>Do not forget to provide the mobile number of the presenter along with the paper.</li>
        </ol>

        {/* Add format for Paper Presentation */}
        <h2>Format for Paper Presentation (Podium) in Competitive Section</h2>
        <ul className="format-list">
          <li><strong>Font:</strong> Arial/Helvetica/Verdana, Size: 24-34 point, no more than 2 font types per slide. Use lower case only, no more than 6 lines of 6 words per slide.</li>
          <li>No spelling mistakes, use bullet points, avoid irrelevant/personal images, avoid animation if possible.</li>
          <li><strong>Color:</strong> Maximum 2 colors. Background: single bright color (preferably deep blue with white and yellow font).</li>
          <li>Use more graphics/video if possible, present figures as diagrams or graphs, hide patient identity.</li>
          <li>Maintain consistent format, font, and layout.</li>
        </ul>

        {/* Add format for Poster Presentation */}
        <h2>Format for Poster Presentation in Competitive Section</h2>
        <ul className="format-list">
          <li><strong>Size:</strong> 4 feet X 3 feet (H X W).</li>
          <li><strong>Background:</strong> Single bright color.</li>
          <li><strong>Font:</strong> Arial/Helvetica/Verdana, no more than 2 font types per slide. Use lower case only, avoid spelling mistakes, use bullet points, and avoid irrelevant/personal images.</li>
          <li><strong>Color:</strong> Maximum 2 colors.</li>
        </ul>
      </div>
        <div className="form-group">
          <label htmlFor="submissionType">Submission Type</label>
          <select disabled id="submissionType" value={submissionType} onChange={handleSubmissionTypeChange}>
            <option value="">Select Submission Type</option>
            <option value="PG Gold Medal Paper Presentation">PG Gold Medal Paper Presentation</option>
            <option value="Basic Science Paper Presentation">Basic Science Paper Presentation</option>
            <option value="Free Paper Presentation">Free Paper Presentation</option>
            <option value="Poster Presentation">Poster Presentation</option>
          </select>
        </div>

        {submissionType && (
  <div className="form-group">
    <label htmlFor="fileUpload">Upload Abstract</label>
    <input disabled
      type="file"
      id="fileUpload"
      accept=".doc,.docx"
      onChange={handleFileChange}
    />
    <p className="file-note">Only .doc or .docx files are allowed</p>
  </div>
)}


        <button disabled type="submit" className="submit-button">Submit</button>
      </form>
      )}

      
    </div>
  );
};

export default AbstractSubmission;
