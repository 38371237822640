import React from 'react';
import './Speakers.css';
import Carousel from 'react-bootstrap/Carousel';
import Shaivashankar from '../assets/images/shivashankar.png';
import Mohanty from '../assets/images/mohanty.png';
import John from '../assets/images/john.png';

const Speakers = () => {
    const speakers = [
        {
            name: "Dr. B. Shivashankar",
            qualification: "Honourable Past President, IOA.",
            image: Shaivashankar,
        },
        {
            name: "Dr. S. S. Mohanty",
            qualification: "Professor, KEM Hospital, Mumbai.",
            image: Mohanty,
        },
        {
            name: "Dr. John Mukhopadhaya",
            qualification: "Director, Paras HMRI Hospital, Patna.",
            image: John,
        },
        // {
        //     name: "Coming Soon",
        //     qualification: "-",
        //     image: dummymage,
        // },
    ];

    return (
        <div className="speakers-container">
            <h2 className="speakers-title">Our Esteemed Speakers</h2>
            <Carousel interval={3000} className="speakers-carousel">
                {speakers.map((speaker, index) => (
                    <Carousel.Item key={index}>
                        <div className="carousel-item-content">
                            <img
                                className="d-block w-100 carousel-image"
                                src={speaker.image}
                                alt={`${speaker.name}`}
                            />
                            <div className="carousel-caption">
                                <h3>{speaker.name}</h3>
                                <p>{speaker.qualification}</p>
                            </div>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};

export default Speakers;
